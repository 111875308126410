@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  /* font-family: "RALEWAY";
  src: url("../public/fonts/Raleway-VariableFont_wght.ttf"); */
  font-family: "Work Sans";
  src: url("../public/fonts/WorkSans-VariableFont_wght.ttf");
}

@layer base {

  * {
    /* @apply font-raleway; */
    @apply font-worksans;
  }

  h1 {
    @apply text-2xl md:text-3xl;
    @apply font-bold;
    @apply font-black;
    @apply text-primary;
    @apply underline-offset-[12px];
    @apply sm:underline-offset-[16px];
    @apply decoration-8;
    @apply md:leading-tight;
  }
  h2 {
    @apply text-xl md:text-2xl;
    @apply font-semibold;
    @apply font-black;
    @apply text-primary;
    @apply decoration-8;
    @apply md:leading-tight;
    @apply underline-offset-[8px];
    @apply sm:underline-offset-[14px];
  }

  h3 {
    @apply text-lg md:text-xl;
    @apply font-semibold;
    @apply decoration-8;
    @apply underline-offset-[8px];
    @apply sm:underline-offset-[14px];
  }
  h4 {
    @apply text-lg;
    @apply font-medium;
    @apply decoration-8;
    @apply underline-offset-[8px];
    @apply lg:underline-offset-[12px];
  }
  h5 {
    @apply font-medium;
    @apply underline-offset-4;
  }
  p {
    /* @apply text-xl; */

    @apply underline-offset-4;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

body {
  display: flex;
  flex-direction: column;
}



html,
body {
  overscroll-behavior: hidden;
  min-height: 100vh;
  font-family: sans-serif;
}
